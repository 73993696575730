import React, { useState } from 'react';
import { string } from 'prop-types';
import { navigate } from 'gatsby';

import { ALL_CARDS_ROUTE } from 'constants/navigation';

import { useNoticationContext } from 'contexts/notification-context';
import { useStateContext } from 'contexts/state-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';

import { faEraser } from '@fortawesome/pro-light-svg-icons';

import {
  Paper,
  Button,
  Grid,
  Hidden,
  CircularProgress,
  Typography,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
  Checkbox,
} from '@material-ui/core';
import useStyles from './styles';

const DeleteCardView = ({ passId, title }) => {
  const styles = useStyles();
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const noticationContext = useNoticationContext();
  const stateContext = useStateContext();

  const onSubmit = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setLoading(true);
    if (isChecked) {
      const getDeletePassService = await import('services/passes/delete-pass-service');
      const { deleteLoyaltyPass } = getDeletePassService.default({
        stateContext,
        noticationContext,
      });
      await deleteLoyaltyPass({ passId });
      navigate(ALL_CARDS_ROUTE);
    } else {
      setError(true);
      setLoading(false);
    }
  };

  return (
    <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
     >
    <Grid item xs={12} md={10} xl={8}>
      <Paper className={styles.paper} elevation={4}>
      <form autoComplete="off" id="delete-loyalty-card">
        <Grid spacing={2}
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
          >

          <Grid item xs={12} sm={10} className={styles.fourTopBotMargin}>
            <Typography
              variant="h4"
              component="h3"
              color="inherit"
              align="center"
              className={styles.headerGreen}>
              Erase from existence
              <FontAwesomeIcon icon={faEraser} size="lg" className={styles.headerIcon}/>
              <Typography
                variant="caption"
                component="div"
                align="right"
                className={styles.headerSubText}>
                  {'Deleted cards can\'t be recovered but you can create more'}
              </Typography>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={10}>
            <FormControl
            error={error}
            >
            <FormLabel
              htmlFor="delete-loyalty-card"
              className={styles.pickerLabel}>
              {error ? 'Please check this box to delete the loyalty card' : `${isChecked ? '' : 'Please check this box to continue.'}`}
              </FormLabel>
              <FormControlLabel
               control={
                 <Checkbox
                 id="delete-loyalty-card"
                  color="secondary"
                  checked={isChecked}
                  aria-label="agree to delete loyalty card"
                  onChange={() => {
                    setIsChecked(!isChecked);
                    if (error) {
                      setError(false);
                    }
                  }}
                  name="checkedG"
                  />}
               label={`Delete loyalty card: ${title}`}
               />
               <FormHelperText>{`${
                 error
                   ? 'Required to continue.'
                   : 'You can only delete not active loyalty cards.'
               }`}</FormHelperText>
             </FormControl>
          </Grid>

          <Grid item xs={12} sm={10}>
         <Hidden smUp>
           <Button
             variant="contained"
             color="primary"
             className={styles.formbutton}
             onClick={onSubmit}
             type="submit"
             fullWidth
             aria-label="delete card"
             disabled={loading}
             endIcon={loading
               ? <CircularProgress className={styles.submitLoading}/>
               : (
                 <FontAwesomeIcon icon={faTrashAlt} className={styles.submitIcon}/>
               )
             }
             >
            Delete
          </Button>
         </Hidden>
         <Hidden xsDown>
           <Button
             variant="contained"
             color="primary"
             className={styles.formbutton}
             onClick={onSubmit}
             type="submit"
             fullWidth
             aria-label="delete card"
             disabled={loading}
             endIcon={loading
               ? <CircularProgress className={styles.submitLoading}/>
               : (
                 <FontAwesomeIcon icon={faTrashAlt} className={styles.submitIcon}/>
               )
             }
             >
            Delete Loyalty card
          </Button>
         </Hidden>
          </Grid>
        </Grid>
      </form>
      </Paper>
    </Grid>
  </Grid>
  );
};

DeleteCardView.propTypes = {
  passId: string.isRequired,
  title: string.isRequired,
};

export default DeleteCardView;
